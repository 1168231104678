import seedFlexLogo from '@assets/icons/seedflexLogo.svg';
import seedflexTabLogo from '@assets/icons/seedflexTabLogo.svg';
import {PATH, WHATS_APP_NUMBER} from '@common/constants';
import {Avatar, useMediaQuery} from '@mui/material';
import HomeIcon from '@shared/icons/HomeIcon';
import ProfileIcon from '@shared/icons/Profile';
import QuestionIcon from '@shared/icons/QuestionIcon';
import {useState} from 'react';
import {useLocation} from 'react-router';
import PrimaryLogout from '@assets/icons/PrimaryLogout.svg';

import {RootState, dispatch} from '@store';
import {
  loginActions,
  loginReducerLogout,
} from '@pages/Login/auth/store/loginReducer';
import {logoutApi} from '@pages/Login/api';
import LogoutModal from '@pages/Login/component/LogoutModal';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  AvatarWrapper,
  GridItem,
  IconWrapper,
  ListItemTextContent,
  Logo,
  LogoutButton,
  SidebarItem,
  SidebarItems,
  SidebarWrapper,
} from './Sidebar.style';
import Stepper from '../Stepper';

export default function Sidebar() {
  const {stepToRedirect, step} = useSelector((state: RootState) => state.login);
  const location = useLocation();
  const isTabScreen = useMediaQuery('(max-width:1120px)');
  const isMobScreen = useMediaQuery('(max-width:480px)');

  const [activeItem, setActiveItem] = useState('Home');
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();

  const items = [
    {
      icon: (
        <HomeIcon
          color={activeItem === 'Home' ? '#000000' : '#757575'}
          width='24px'
          height='24px'
        />
      ),
      link: PATH.DASHBOARD,
      text: t('sidebar.MP_SIDEBAR_HOME'),
      id: 'Home',
    },
    {
      icon: (
        <ProfileIcon
          color={activeItem === 'My Profile' ? '#121212' : '#757575'}
          width='24px'
          height='24px'
        />
      ),
      text: t('sidebar.MP_SIDEBAR_PROFILE'),
      link: PATH.PROFILE,
      id: 'My Profile',
    },
    {
      icon: (
        <QuestionIcon
          color={activeItem === 'Contact Us' ? '#000000' : '#757575'}
          width='24px'
          height='24px'
        />
      ),
      text: t('sidebar.MP_SIDEBAR_CONTACT'),
      link: `https://wa.me/${WHATS_APP_NUMBER}`,
      id: 'Contact Us',
    },
  ];
  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  const LogoutResponse = async () => {
    const res = await logoutApi();
    if (res?.data?.message === 'Log out successfully') {
      // dispatch(loginActions.logout());
      dispatch(loginReducerLogout());
      dispatch(loginActions.setIsShowOtp(false));
      dispatch(loginActions.setIsSelectBRN(false));
      dispatch(loginActions.setIsLogout(true));
    }
  };
  const handleLogout = () => {
    LogoutResponse();
  };
  const handleClickLogoutIcon = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <GridItem item xs={9.65} sm={4} md={2.45} lg={2.45} xl={1.8}>
      {location.pathname !== '/' && isMobScreen ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      ) : (
        <SidebarWrapper isMobScreen={isMobScreen}>
          <SidebarItems isMobScreen={isMobScreen}>
            {!isTabScreen && !isMobScreen && (
              <Logo src={seedFlexLogo} alt='Logo' />
            )}
            {isTabScreen && !isMobScreen && (
              <Logo src={seedflexTabLogo} alt='TabLogo' />
            )}
            {stepToRedirect ? (
              <div className='stepper-section'>
                <Stepper currentStep={step} orientation='vertical' />
              </div>
            ) : (
              items.map((item) => (
                <SidebarItem
                  isMobScreen={isMobScreen}
                  key={item.id}
                  to={item.link}
                  target={item.id.includes('Contact') ? '_blank' : '_self'}
                  onClick={() => handleItemClick(item.id)}
                  isActive={activeItem === item.id}>
                  <IconWrapper
                    isMobScreen={isMobScreen}
                    isActive={activeItem === item.id}>
                    {item.icon}
                  </IconWrapper>
                  <ListItemTextContent
                    isMobScreen={isMobScreen}
                    isActive={activeItem === item.id}
                    primary={item.text}
                  />
                </SidebarItem>
              ))
            )}
          </SidebarItems>

          <LogoutButton onClick={handleClickLogoutIcon}>
            <AvatarWrapper>
              <Avatar alt='Logout' src={PrimaryLogout} />
            </AvatarWrapper>
            <div className='logout-text'>{t('sidebar.MP_SIDEBAR_LOGOUT')}</div>
          </LogoutButton>

          <LogoutModal
            isOpen={open}
            handleLogout={handleLogout}
            handleCancle={handleCloseModal}
          />
        </SidebarWrapper>
      )}
    </GridItem>
  );
}
